<template>
	<!-- flex_column -->
	<div class="industry_page">
		<!-- 按钮搜索区域 -->
		<hangar :isPhone="isPhone" :buttonList="buttonList" @curBtn="changeIndex" :curBtn="curBtn"
			:title="$t('industryNews.name')">
		</hangar>

		<!-- 返回按钮 -->
		<div id="back" v-show="isPhone" @click="back"
			style="z-index:999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<div class="top">
			<el-row>
				<el-col :xs="0" :sm="4" :lg="4">
					<div class="grid-content bg-purple"></div>
				</el-col>
				<el-col :xs="24" :sm="16" :lg="16">
					<el-row :gutter="0" style="padding: 20px;">
						<el-col :xs="24" :sm="24" style="margin-bottom:30px" class="zui-flex-row"
							v-for="(item,i) in listData" :key="i" :id="'id'+item.id.toString()">
							<div class="top_left zui-pointer" @click="toDetail(item)" >
								<el-image class="plane-img zui-border-radius-small" style="width: 100%;"
									:src="item.publishImage == null?'../assets/image/news.png':$apiUrl+'/'+item.publishImage"
									fit="fill"></el-image>
								<div class="tag">{{item.titleType_dictText}}</div>
							</div>

							<div @click="toDetail(item)"
								class="zui-m-l-10 top_right  zui-pointer zui-flex-column zui-justify-center zui-h-100">
								<div class="zui-p-b-20 zui-font-sm">{{item.titleName}}</div>
								<div class="zui-text-secondary zui-font-xs">{{setformat(item.createTime)}}</div>
							</div>
						</el-col>
					</el-row>
				</el-col>
				<el-col :xs="0" :sm="4" :lg="4">
					<div class="grid-content bg-purple"></div>
				</el-col>
			</el-row>
			<div class="moreBox zui-m-b-20">
				<div class="more-load" v-if="isMoreLoad" @click="scrollLoadMore">
					<span v-if="loadingImg">{{$t('industryNews.moreText')[1]}}</span>
					<span v-else>{{$t('industryNews.moreText')[0]}}</span>
				</div>
				<div class="more-load" v-if="loadLastText">{{$t('industryNews.moreText')[2]}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 行业动态页面
	import hangar from '../components/hangarCom';
	import {
		getNews
	} from '../api/api';
	export default {
		components: {
			hangar
		},
		data() {
			return {
				isPhone: false,
				buttonList: [],
				curBtn: 0,
				listData: [],
				formData: {
					_t: '',
					titleType: 0,
					column: 'createTime',
					order: 'desc',
					field: 'id,titleName,titleType_dictText,titleContent,publishStatus_dictText,introduction,publishImage,endTime,action',
					pageNo: 1,
					pageSize: 10
				},
				definePafeSize: 10,
			};
		},
		watch: {
			$route(from) {
				// 监听路由变化, 实现类似 小程序的 onShow 事件
				if (from.params.id) {
					this.curBtn = from.params.id - 1;
				} else {
					this.curBtn = 0;
				}
				this.formData.titleType = this.curBtn;
				this.getList();
			}
		},
		created: function() {
			if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
				this.isPhone = true
			}
			this.formData._t = Date.parse(new Date());
			this.getList();
			//console.log(this.$route.params)
		},
		mounted: function() {
			this.scrollLoadMoreEvn();
			let id = this.$route.params.id
			if (id) {
				setTimeout(()=>{
					this.to_scroll(id)
				},500)
				
			}
		},
		methods: {
			to_scroll(e = 0) {
				let posi = 'id'+e.toString()
				if (posi) {
					var dom = document.getElementById(posi)

					if (dom) {
						dom.scrollIntoView({
							behavior: "smooth", // 平滑过渡
							block: "center" // 滚到屏幕中间
						})
					}

				}
			},
			setformat(e) {
				if (e) {
					let str = e
					var reg = /\d{4}-\d{1,2}-\d{1,2}/g.exec(str)
					return reg[0]
				}
			},
			changeIndex(e) {
				this.curBtn = e;
				this.formData.pageNo = 1;
				this.formData.titleType = e;
				this.getList();
			},
			getList: function() {
				let queryData = {};
				queryData.pageNo = this.definePageNum;
				queryData.pageSize = this.definePafeSize;


				getNews(queryData).then(res => {
					// console.log(res);
					if (res.code == 200) {
						//分页 - S
						//判断是否是第一页
						this.totals = res.result.total; //总数据 
						if (this.definePageNum >= 2) {
							//追加数据
							this.listData = this.listData.concat(res.result.records)
						} else {
							this.listData = res.result.records

						}

						if (this.totals - this.definePageNum * this.definePafeSize > 0) {
							this.isMoreLoad = true; //显示 加载更多 按钮
						} else {
							this.isMoreLoad = false; //不再显示 加载更多 按钮
							this.loadLastText = true; //显示 没有更多数据了 按钮
						}
						//分页++
						this.definePageNum++;
						// 加载完成后停止加载动画
						this.loadingImg = false;
						//分页 - e

					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			toDetail(item) {
				// console.log(item);
				this.$router.push({
					name: 'industryNewsDetail',
					params: {
						detail: item,
					}
				});
			},
			//加载更加
			scrollLoadMore() {
				// 防止多次加载
				if (this.loadingImg) {
					return;
				}
				//开启加载动画
				this.loadingImg = true;
				//调取 API
				this.getList();
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/config.scss';
	@import '../assets/scss/mixin.scss';


	.industry_page {
		padding: 0;
		margin: 0;

		.top {
			padding: 20px 0;
			background: $colorG;

			.top_left {
				position: relative;
				width: 40%;
				height: 100%;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.top_right {
				flex: 1;
				height: 100%;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				align-items: flex-start;
			}
		}
	}

	.el-col-a {
		height: 100%;
	}

	.el-row-a {
		height: 260px;
	}

	.el-row {
		width: 100%;
	}

	.el-col {
		border-radius: 4px;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.newImg {}

	//飞机宽高
	/deep/ .plane-img {
		padding-top: 60%;
		width: 100%;
		position: relative;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
		}
	}

	.tag {
		position: absolute;
		background-color: #000000;
		border-radius: 0 20px 20px 0;
		color: #ffffff;
		line-height: 32px;
		font-size: 14px;
		text-align: center;
		top: 8%;
		padding: 0px 10%;
		height: 32px;
		border: 1px solid #000000;
	}

	@media only screen and (max-width: 768px) {
		.el-row-a {
			height: 200px;
		}

		.el-top_right {
			height: auto !important;
			padding: 20px 0;

			.top_right {
				height: auto !important;
			}
		}

		.industry_page {
			.top {
				padding: 0;
			}
		}

		/deep/ .tag {
			height: 20px !important;
			line-height: 20px;
			font-size: 10px;
			padding: 0px 10%;
		}

		/deep/ .top_right {
			font-size: 12px !important;
		}

		/deep/ .bottom_right {
			font-size: 12px !important;
		}
	}

	.hide {
		display: none;
	}
</style>
